/**
 * Timetastic
 * Staff absence policy template page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/downloads.scss"

// SEO
const title = "Staff Absence Policy - Free to Download"
const description =
  "FREE Download. Staff Absence Policy Template. Download in any format. The customisable Policy Template covers Staff Absence and Sick leave."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-download-template-main">
          {/* Hero */}
          <section className="c-section c-download-hero u-text-centre u-text-left-mobile">
            <div className="u-inner c-download-hero__inner is-unanimated">
              <h1 className="h h1 c-download-hero__title">
                Staff absence policy
              </h1>
              <p>
                Most sickness is genuine. Unplanned absence might be caused by
                family commitments or stress and these situations, being
                unexpected, can be difficult to manage. A clear policy helps
                avoid conflict and relieve uncertainty during those unsettling
                periods of time.
              </p>

              <div className="">
                <h2 className="h h5">Download our completely free template</h2>

                <ul className="c-button-list">
                  <li className="">
                    <a
                      href="/documents/Timetastic_staff_absence_policy_template.pdf"
                      className="c-button c-button--primary c-button--xl"
                      download
                      rel="noreferrer noopener"
                      target="_blank"
                      onClick={() => {
                        trackCta("AbsencePolicyDownload_PDF")
                      }}
                    >
                      <svg
                        focusable="false"
                        height="24"
                        viewBox="0 0 20 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref="#svg-icon-pdf" />
                      </svg>
                      Download as PDF
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="/documents/Timetastic_staff_absence_policy_template.docx"
                      className="c-button c-button--secondary c-button--xl"
                      download
                      rel="noreferrer noopener"
                      target="_blank"
                      onClick={() => {
                        trackCta("AbsencePolicyDownload_DOCX")
                      }}
                    >
                      <svg
                        focusable="false"
                        height="24"
                        viewBox="0 0 20 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref="#svg-icon-word" />
                      </svg>
                      Download as Word
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="https://docs.google.com/document/d/1zgd9ULRZ1Xc8G88QBDkuihxaZtH5rg0ekSIVngDzE1A/edit?usp=sharing"
                      className="c-button c-button--tertiary c-button--xl"
                      download
                      rel="noreferrer noopener"
                      target="_blank"
                      onClick={() => {
                        trackCta("AbsencePolicyDownload_GDOC")
                      }}
                    >
                      <svg
                        focusable="false"
                        height="24"
                        viewBox="0 0 20 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref="#svg-icon-google-doc" />
                      </svg>
                      Open Google Doc
                    </a>
                  </li>
                </ul>
                <div className="c-download__plc">
                  <img
                    loading="eager"
                    alt="Plain Language Commission"
                    src="/images/plain-language-commission.svg"
                    width="63px"
                    height="80px"
                  />
                  <p>Accredited by the Plain Language Commission</p>
                </div>
              </div>
            </div>
          </section>
          {/* Information */}
          <section className="c-section c-download-questions">
            <div className="u-inner u-inner--l">
              <div className="c-download-faq">
                <div className="c-pricing-question is-unanimated">
                  <h2 className="h h4">How to use the template</h2>
                  <p>
                    Once you have downloaded the template you’ll need to have a
                    good read through and fill in any blanks.
                  </p>
                  <p>
                    It’s important that you check through the template carefully
                    to make sure it aligns with your existing policies,
                    procedures and contracts of employment.
                  </p>
                  <p>
                    Please do make sure that you're complying with relevant
                    employment law and if in any doubt seek help from an
                    employment law specialist.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h2 className="h h4">What’s included?</h2>
                  <p>
                    This policy covers sickness, where your employees might have
                    medical issues that prevent them from working. Typically
                    they would self certify or get a doctor's note if the
                    illness lasts more than seven days.
                  </p>
                  <p>
                    It also covers situations where they feel unable to come to
                    work if for example they're unhappy, lack motivation, or
                    because of family or caring responsibilities.
                  </p>
                  <p>
                    This policy is inline with the ACAS Sample Staff Absence
                    Policy - published in their booklet
                    <a
                      href="http://www.acas.org.uk/index.aspx?articleid=1183"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      "Managing Attendance and Employee Turnover"
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <FreeTrial
            body="Lawyers, accountants, web design agencies, car dealers, manufacturers, online retailers, charities, coffee roasters – over 7,000 businesses like yours manage staff absence with Timetastic."
            buttonCopy="Start your free trial"
            buttonHref="/pricing/"
            title="Get one month free!"
            trackCtaLabel="AbsencePolicyTemplateSignUp"
          />
          <section className="c-section c-download-wording">
            <div className="u-inner">
              <h2 className="h h2">The policy wording</h2>
              <h3 className="h h3">Staff Absence Policy</h3>
              <h4 className="h h4">What is this policy for?</h4>
              <p>
                We care about the health, wellbeing and attendance of everyone
                who works for us.
              </p>
              <p>
                Everyone’s contribution helps towards the company’s success. So
                when someone can’t work for any reason, we miss that
                contribution. This absence policy explains:
              </p>
              <ul>
                <li>
                  what we expect from managers and employees when handling
                  absence
                </li>
                <li>how we’ll work to reduce levels of absence.</li>
              </ul>
              <p>
                This policy is in line with ACAS guidelines. We welcome any
                ideas that may help improve it.
              </p>
              <h4 className="h h4">Key principles</h4>
              <ol>
                <li>
                  We don’t want anyone to struggle financially because they’re
                  unable to work through illness.
                </li>
                <li>We want to have a happy and healthy workforce.</li>
                <li>Regular, punctual attendance is important.</li>
                <li>
                  We’ll support those who have genuine grounds for absence. This
                  support includes:
                  <ol>
                    <li>
                      ‘special leave’ for necessary absences not caused by
                      sickness
                    </li>
                    <li>a flexible approach to taking annual leave</li>
                    <li>access to counsellors where necessary</li>
                    <li>
                      rehabilitation support in cases of long-term sickness.
                    </li>
                  </ol>
                </li>
                <li>
                  We’ll take into account any advice given by a doctor on a
                  ‘Statement of Fitness for Work’.
                </li>
                <li>
                  If needed, we’ll bring in an occupational health adviser to
                  help find the best way to improve someone’s health and
                  wellbeing.
                </li>
                <li>
                  We’ll use the disciplinary procedures when someone can’t
                  explain their absence or we don’t think the reason is
                  satisfactory.
                </li>
                <li>
                  There’s a lot of sensitive information regarding sickness.
                  Everything we do will be in line with data protection
                  legislation and the Access to Medical Records Act 1988.
                </li>
              </ol>
              <h4 className="h h4">
                What you should do if you’re too ill to work
              </h4>
              <p>
                If you can’t work, let your manager know as soon as possible,
                ideally within <em>[specify time period e.g. one hour]</em> of
                your start time. You’ll need to give them:
              </p>
              <ul>
                <li>an indication of your illness, and</li>
                <li>a likely return date.</li>
              </ul>
              <p>
                Your manager will check whether there’s any information they
                need to cover your work while you’re off.
              </p>
              <p>
                If you haven’t contacted your manager within [specify time
                period e.g. one hour] of your start time, we’ll try to contact
                you.
              </p>
              <p>
                If you don’t feel you can discuss medical problems with your
                manager, please speak to someone you feel comfortable with.
              </p>
              <h4 className="h h4">Do we keep a record of sick leave?</h4>
              <p>
                <em>[If you use Timetastic]</em>
              </p>
              <p>
                We use Timetastic to keep track of all time off work, and we’ll
                give you the login details you need. Sick leave is recorded in
                Timetastic like any other absence. The summary shows you how
                much you’ve taken each year.
              </p>
              <p>
                <em>[If you don’t use Timetastic]</em>
              </p>
              <p>Describe your system for keeping a record sick leave here.</p>
              <h4 className="h h4">When you need to get a sick note</h4>
              <p>
                You can self-certify your sickness for the first seven days.
              </p>
              <p>
                After that you’ll need to get a Statement of Fitness for Work
                (‘Fit note’) from your doctor.
              </p>
              <p>
                If you’re likely to be off more than four weeks, we’ll agree a
                timetable with you for making contact, so we can keep each other
                updated.
              </p>
              <h4 className="h h4">
                What if your doctor says you ‘may be fit for some work’?
              </h4>
              <p>
                If your doctor says you ‘may be fit for work’, your doctor
                should give some details of the level of work they think you’re
                able to do.
              </p>
              <p>
                We’ll work with that advice and agree ways of helping you get
                back to work. For example, we might look at a phased return to
                work or different duties.
              </p>
              <p>
                It may be that we can’t provide the support you need by making
                the necessary workplace adjustments, or you may not feel able to
                return. If so, we’ll use the fit note in the same way as if the
                GP advised you were ‘not fit for work’.
              </p>
              <h4 className="h h4">What happens when you come back to work?</h4>
              <p>
                When you return to work you’ll need to have a discussion with
                your manager so they can:
              </p>
              <ul>
                <li>understand the reason for your absence</li>
                <li>see what they or the company can do to help</li>
                <li>be sure that you’re really fit to return to work</li>
                <li>agree any adjustments to your working conditions.</li>
              </ul>
              <h4 className="h h4">What triggers a formal review?</h4>
              <p>
                If your absences become disruptive to our operations, we’ll need
                to look at further action to help improve your attendance.
              </p>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
